import React from "react";
import errorPage from "../assets/PageNotFound.png";

function PageNotFound() {
  return (
    <div>
      <img
        className="img-fluid"
        src={errorPage}
        alt="404 not found"
        height="auto"
        width="auto
      "
      />
    </div>
  );
}

export default PageNotFound;
