import logo from "../assets/logo_sept.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import axios from "axios";

function Navbar({ setAuth }) {
  const logout = (e) => {
    e.preventDefault();

    // get user_id from token
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];
    // update the logout
    // User log in failed
    window.gtag("event", "user_logged_out", {
      app_name: "Knowtex-Webapp",
      screen_name: "DashBoard",
      job_name: user_id,
    });

    // Define the event
    const userEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_logged_out",
        userId: user_id,
        screenName: "DashBoard",
        timestamp: new Date().toISOString(),
      },
    };
    const endpointUrl =
      "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";
    axios
      .post(endpointUrl, userEvent)
      .then((response) => {
        //console.log("Event sent successfully:", response.data);
      })
      .catch((error) => {
        //console.error("Failed to send event:", error);
      });

    //console.log("User:%s logged out \n", user_id);

    localStorage.removeItem("token");
    localStorage.removeItem("date");

    setAuth(false);
    toast.success("Logout successful");
  };
  return (
    <nav
      className="navbar navbar-expand-lg"
      style={{ backgroundColor: "#ffffff" }}
    >
      <div className="container-fluid ">
        <Link to="/dashboard">
          <img src={logo} width="160" height="53" alt="Knowtex" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon "></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link to="/" className="nav-link active " aria-current="page">
              Home
            </Link>
            <Link to="/notes" className="nav-link active ">
              All Notes
            </Link>
            <a href="https://atlas-kb.com/knowtex" className="nav-link active">
              Help Center
            </a>
            <button className="btn btn-grey" onClick={(e) => logout(e)}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
