import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

export default function CheckoutForm({ setIsSubscribed }) {
  //grabbing userId from jwt
  const decoded_token = jwt_decode(localStorage.token);
  const userId = decoded_token.user.id;

  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [fullName, setFullName] = useState("");

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");

  const [err, setErr] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  // to grab email and name when they load the page initially
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/dashboard/userinfo`,
          {
            method: "GET",
            headers: { token: localStorage.token },
          }
        );

        const parseRes = await response.json();
        setFullName(parseRes[0].user_name);
        setEmail(parseRes[0].user_email);
      } catch (err) {
        console.error("Error fetching user info:", err.message);
      }
    })()
  }, [])

  // helper to auto-fill the name with current userinfo
  useEffect(() => {
    const splitName = fullName.split(" ");

    setFName(splitName[0]);
    setLName(splitName[1]);
  }, [fullName])

  //display price based on env value
  const price = parseInt(process.env.REACT_APP_STRIPE_VAL).toFixed(2);

  const handleStripeSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: email,
        name: `${fName} ${lName}`
      },
    });

    if (error) {
      setIsLoading(false);
      return;
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/stripe-subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ paymentMethod, name: `${fName} ${lName}`, email, userId }),
    });

    const subscription = await response.json();

    if (subscription.error) {
      toast.error(subscription.error.message);
      toast.error("If you feel this is an error, please contact support@knowtex.ai");
    } else {
      setIsSubscribed(true);
      toast.success("Subscription Successful")
      navigate("/login");
      localStorage.removeItem("subscription_ends");
    }

    setIsLoading(false);
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setIsDisabled(event.empty);
    setErr(event.error ? event.error.message : "");
  };

  useEffect(() => {
    if (err) {
      toast.error(err);
    }
  }, [err])

  return (
    <form id="payment-form" onSubmit={handleStripeSubmit}>
      <h5 className="text-accent2 font-semibold text-[1.25rem] tracking-[.05rem]">Subscribe to Knowtex</h5>
      <div className="flex text-accent1 gap-1 mb-[1.5rem]">
        <h3 className="text-[2rem] font-bold">${price}</h3>
        <p className="tracking-[0.08rem]">per month</p>
      </div>
      <input type="text" className="w-[100%] my-[1rem] border-none shadow-knowtex-shadow rounded-md text-accent1" required value={fName} placeholder="First Name" onChange={(e) => setFName(e.target.value)} />
      <input type="text" className="w-[100%] my-[1rem] border-none shadow-knowtex-shadow rounded-md text-accent1" required value={lName} placeholder="Last Name" onChange={(e) => setLName(e.target.value)} />
      <input type="email" className="w-[100%] my-[1rem] border-none shadow-knowtex-shadow rounded-md text-accent1" required id="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
      <CardElement className="px-[.5rem] py-[.75rem] w-[100%] my-[1rem] border-none shadow-knowtex-shadow rounded-md text-accent1" id="payment-element" onChange={handleChange} />
      <button className="mt-[3rem]" disabled={isLoading || !stripe || !elements || isDisabled} id="submit">
        <span id="button-text" className="tracking-[.1rem]">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Subscribe"}
        </span>
      </button>
      <p className="mx-auto mt-[1.5rem] text-accent1 text-center w-[60%]"><button className="hover:text-accent1 text-accent2 font-semibold" onClick={() => window.Atlas.chat.openWindow()} >Questions or concerns?</button> Please reach us at <a className="hover:text-accent1 text-accent2 font-semibold" href="mailto:support@knowtex.ai">support@knowtex.ai</a> or call <a className="hover:text-accent1 text-accent2 font-semibold" href="tel:+1-323-679-6957">+1 (323) 679-6957</a></p>
    </form>
  );
}