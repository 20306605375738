import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import axios from "axios";
import LogRocket from "logrocket";
import purpleLogo from "../assets/purpleKnowtexLogo.svg";
// comment below back in for old login
// import css from "./login.module.css"
// import logo from "../assets/logo_sept.png";
// import loginBackground from "../assets/backgroundPattern.svg";

//console.log(process.env.REACT_APP_API_URL);
const Login = ({ setAuth, setIsSubscribed }) => {
  const [inputs, setInputs] = useState({ email: "", password: "" });

  const { email, password } = inputs;

  const onChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  function sendEvent(userEvent) {
    // Replace "http://example.com/api/events" with the actual endpoint URL.
    const endpointUrl =
      "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";

    axios
      .post(endpointUrl, userEvent)
      .then((response) => {
        //console.log("Event sent successfully:", response.data);
      })
      .catch((error) => {
        //console.error("Failed to send event:", error);
      });
  }

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const body = { email, password };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      const parseRes = await response.json();

      if (parseRes.token) {
        localStorage.setItem("token", parseRes.token);
        setAuth(true);
        toast.success("Login successful");

        // get user_id from token
        const decoded_token = jwt_decode(localStorage.token);
        const user_id = decoded_token["user"]["id"];

        // User log in successfully
        window.gtag("event", "user_log_in", {
          app_name: "Knowtex-Webapp",
          screen_name: "Login Page",
          job_name: user_id,
        });

        //log rocket
        LogRocket.identify(user_id, {
          email: email,
        });

        //atlas
        window.Atlas.call("identify", {
          userId: user_id,
          email: email,
        });

        // custom logging
        const cur_env = process.env.REACT_APP_API_URL.includes("localhost")
          ? "local"
          : "prod";
        // Define the event
        const userEvent = {
          env: cur_env,
          service: "knowtex-webApp",
          event: {
            eventName: "user_log_in",
            userId: user_id,
            screenName: "Login Page",
          },
        };
        sendEvent(userEvent);

        //console.log("User:%s log in succeed",email)

        //checks subscription status
        const subscriptionResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/subscription-status`,
          {
            method: "GET",
            headers: { token: localStorage.token },
          }
        );

        const subscriptionData = await subscriptionResponse.json();
        setIsSubscribed(subscriptionData.is_subscribed);
      } else {
        // User log in failed
        window.gtag("event", "user_login_failed", {
          app_name: "Knowtex-Webapp",
          screen_name: "Login Page",
          job_name: email,
        });

        //console.log("User:%s log in failed",email)
        // custom logging
        // Define the event
        const userEvent = {
          env: process.env.REACT_APP_API_URL.includes("localhost")
            ? "local"
            : "prod",
          service: "knowtex-webApp",
          event: {
            eventName: "user_login_failed",
            screen_name: "Login Page",
            device: navigator.userAgent,
            timestamp: new Date().toISOString(),
          },
        };
        sendEvent(userEvent);

        setAuth(false);
        toast.error(parseRes);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="bg-left bg-auto bg-login-background w-dvw h-dvh flex flex-col items items-center">
      <img src={purpleLogo} className="min-w-[20rem] max-w-[50rem] w-[30%] h-[40%] my-[1rem]"></img>

      <div className="min-w-[20rem] max-w-[50rem] bg-white rounded-md shadow-drop-shadow w-[30%] h-[45%] flex flex-col justify-center my-[-4rem]">
        <h1 className="text-ktx-navy text-[1.5rem] self-center mb-[4rem] font-medium">Login</h1>
        <form className="flex flex-col items-center gap-[1.7rem]" onSubmit={onSubmitForm}>
          <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[80%]">
            <svg className="self-center ml-[.4rem] w-6 h-6 text-light-grey dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 13h3.439a.991.991 0 0 1 .908.6 3.978 3.978 0 0 0 7.306 0 .99.99 0 0 1 .908-.6H20M4 13v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-6M4 13l2-9h12l2 9" />
            </svg>
            <input
              type="email"
              placeholder="Email"
              name="email"
              className="text-accent1 placeholder:text-light-grey py-[.7rem] w-[100%] italic border-none bg-none rounded-md"
              value={email}
              onChange={(e) => onChange(e)}
              id="email"
              required
            />
          </div>
          <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[80%]">
            <svg className="self-center ml-[.4rem] w-6 h-6 text-light-grey dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a28.076 28.076 0 0 1-1.091 9M7.231 4.37a8.994 8.994 0 0 1 12.88 3.73M2.958 15S3 14.577 3 12a8.949 8.949 0 0 1 1.735-5.307m12.84 3.088A5.98 5.98 0 0 1 18 12a30 30 0 0 1-.464 6.232M6 12a6 6 0 0 1 9.352-4.974M4 21a5.964 5.964 0 0 1 1.01-3.328 5.15 5.15 0 0 0 .786-1.926m8.66 2.486a13.96 13.96 0 0 1-.962 2.683M7.5 19.336C9 17.092 9 14.845 9 12a3 3 0 1 1 6 0c0 .749 0 1.521-.031 2.311M12 12c0 3 0 6-2 9" />
            </svg>
            <input
              type="password"
              name="password"
              placeholder="password"
              className="text-accent1 placeholder:text-light-grey py-[.7rem] w-[100%] italic border-none bg-none rounded-md"
              value={password}
              onChange={(e) => onChange(e)}
              id="password"
              required
            />
          </div>
          <button className="hover:bg-accent1 rounded-md bg-accent2 text-white py-[.7rem] w-[80%] tracking-widest my-[1rem]">Login</button>
        </form>
      </div>
    </div>

    // Old login page


    // <Fragment>
    //   <div className="login-modal card-img-overlay mt-5 ">
    //     <img
    //       className={css.background}
    //       src={loginBackground}
    //       alt="background"
    //     />
    //   </div>
    //   <div
    //     className="mb-5 flex flex-col justify-center"
    //     style={{ marginLeft: "40vw" }}
    //   >
    //     <div className="text-center mb-3 mt-5">
    //       <img className={css.logo} src={logo} alt="logo" />
    //     </div>
    //     <div className="card shadow-lg text-align-center mt-3">
    //       <div className="card-body p-4">
    //         <h1 className="fs-4 card-title fw-bold mb-4">Login</h1>
    //         <form onSubmit={onSubmitForm}>
    //           <div className="mb-3">
    //             <label className="mb-2 text-muted" htmlFor="email">
    //               Email
    //             </label>
    //             <input
    //               type="email"
    //               name="email"
    //               placeholder="email"
    //               className="form-control rounded-md border-0 shadow-knowtex-shadow"
    //               value={email}
    //               onChange={(e) => onChange(e)}
    //               id="email"
    //             />
    //             <div className="invalid-feedback">Email is invalid</div>
    //           </div>

    //           <div className="mb-3">
    //             <div className="mb-2 w-100 ">
    //               <label className="text-muted" htmlFor="password">
    //                 Password
    //               </label>
    //             </div>
    //             <input
    //               type="password"
    //               name="password"
    //               placeholder="password"
    //               className="form-control my-3 rounded-md border-0 shadow-knowtex-shadow"
    //               value={password}
    //               onChange={(e) => onChange(e)}
    //               id="password"
    //             />
    //           </div>
    //           <div>
    //             <button className={css.buttons}>Login</button>
    //             {/**
    //                 <Link to="/register">Register</Link>
    //                 */}
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </Fragment>
  );
};

export default Login;
