import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/logo_oct.png";

import RegistrationForm from "./RegistrationForm";
import RegisterSideBar1 from "./RegistrationSideBar1";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// import RegistrationMain from "./Registration2";

const Register1 = ({ setAuth }) => {
  return (
    <div className="flex">
      <RegisterSideBar1/>
      <div className="h-[100dvh] w-[100dvw] mobile:w-[50dvw] items-center justify-center p-4 mobile:p-12">
      <GoogleReCaptchaProvider reCaptchaKey="6LcEkN0pAAAAAGfK6kb47_zokfKgqpoPJvsiJ0rw">
        <RegistrationForm setAuth={setAuth} />
      </GoogleReCaptchaProvider>
      </div>
    </div>
  );
};

export default Register1;
