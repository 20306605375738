import React from 'react';
import './LoadingScreen.css';

const LoadingScreen = () => {
  return (
    <div className="flex flex-col ml-dashboard-left text-accent1 text-3xl font-extrabold fixed inset-0 items-center justify-center z-50">
      <div className="loader">
      </div>
      <span className="mt-5">
        Loading Notes
      </span>
    </div>
  );
};

export default LoadingScreen;
