import React from "react";
import Record from "./Record";

//Sets up the props for the Record component
class SetUpRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0,
        },
      },
    };
  }

  handleAudioStop(data) {
    this.setState({ audioDetails: data });
  }

  handleReset() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    this.setState({ audioDetails: reset });
  }
  render() {
    return (
      <Record
        name={this.props.name}
        patientID={this.props.patientID}
        record={true}
        setIsAudio={this.props.setIsAudio}
        title={"Ready to Record"}
        audioURL={this.state.audioDetails.url}
        showUIAudio
        handleAudioStop={(data) => this.handleAudioStop(data)}
        handleReset={() => this.handleReset()}
      />
    );
  }
}

export default SetUpRecord;
