import React, { useCallback, useState, useEffect } from "react";
import { Fragment } from "react";
import { useCobaltLink } from "@cobalt-technology/react-link";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import InformationModal from "./InformationModal";

import SideBarV2 from "../SideBarV2/SideBarV2";
import IntegrationModal from "./IntegrationModal";

export default function Settings({ setAuth, setIsSubscribed, isSubscribed }) {
  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [formattedSubEndDate, setFormattedSubEndDate] = useState(null);
  const [linkToken, setLinkToken] = useState(null);
  const [publicToken, setPublicToken] = useState(""); // State to store public token
  const [isReadyToInit, setIsReadyToInit] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false); // Use this state to control the button text
  const [accessToken, setAccessToken] = useState(null);
  const [org, setOrg] = useState();
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openSidebar = () => setIsSidebarOpen(true);
  const closeSidebar = () => setIsSidebarOpen(false);

  // Fetch user info on component mount
  useEffect(() => {
    async function getUserInfo() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/dashboard/userinfo`,
          {
            method: "GET",
            headers: { token: localStorage.token },
          }
        );

        const parseRes = await response.json();
        setUserName(parseRes[0].user_name);
        setUserId(parseRes[0].user_id);
        setOrg(parseRes[0].organization);
        setSubscriptionId(parseRes[0].subscription_id);
        setSubscriptionEndDate(parseRes[0].subscription_end_date);
        setSubscriptionId(parseRes[0].subscription_id);
        setSubscriptionEndDate(parseRes[0].subscription_end_date);
      } catch (err) {
        console.error("Error fetching user info:", err.message);
      }
    }
    getUserInfo();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/subscription-status`,
          {
            method: "GET",
            headers: { token: localStorage.token },
          }
        );

        const parseRes = await response.json();
        setIsSubscribed(parseRes.is_subscribed);
      } catch (err) {
        console.error("Error fetching user info:", err.message);
      }
    })();
  }, [isSubscribed]);

  const handleOpenCancelClick = () => {
    setShowCancellationModal(true);
  };

  const handleOpenInformationClick = () => {
    setShowInformationModal(true);
  };

  useEffect(() => {
    if (subscriptionEndDate) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const formattedDate = new Date(subscriptionEndDate).toLocaleDateString(
        "en-US",
        options
      );
      setFormattedSubEndDate(formattedDate);
      localStorage.setItem("subscription_ends", formattedDate);
    }
  }, [subscriptionEndDate]);

  const getPublicToken = async () => {
    if (!userId) {
      console.error("User ID is not available.");
      return;
    }

    setButtonStatus(true); // Start loading before the fetch request

    //TO-DO: NEED TO STORE COBALT ID AND SECRET IN ENV VARIABLES
    try {
      const response = await fetch(
        "https://api.usecobalt.com/link/token/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            client_id: "ci_live_RRG6m7sZGBWod2xfrhRkw4Ph",
            client_secret: "cs_live_iwoQAkjVRhao7xRbWLLdDUnu",
          },
          body: JSON.stringify({ user_id: userId, org_id: org }),
        }
      );

      if (response.status === 403 || response.status === 409) {
        console.log("Error data  " + response.status);
        console.error("Account link expired. Relinking...");
        const storedAccessToken = await getStoredAccessToken();
        if (storedAccessToken) {
          await relinkAccount(storedAccessToken);
        } else {
          console.error("Unable to get stored access token.");
        }
      } else {
        const data = await response.json();
        if (data && data.token) {
          setLinkToken(data.token);
          setIsReadyToInit(true); // Set ready to initialize
        } else {
          console.error("Failed to obtain link token:", data.message);
          setShowIntegrationModal(true);
          setButtonStatus(false);
          // alert(
          //   "Unable to setup your integration at this time. Please try again or contact Knowtex support. Error details: " +
          //     data.message
          // );
        }
      }
    } catch (err) {
      console.error("Failed to setup integration:", err);
      setShowIntegrationModal(true);
      setButtonStatus(false);
      // alert(
      //   "Unable to setup your integration at this time. Please try again or contact Knowtex support. Error details: " +
      //     err.message
      // );
    } finally {
      setButtonStatus(false); // Stop loading after the fetch request
    }
  };

  const getStoredAccessToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/getaccesstoken`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      if (!response.ok) {
        console.log("Can't get access token " + response);
        alert(
          "Unable to verify credentials to get access token. Please try again or contact Knowtex support to resolve this"
        );
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.accessToken) {
        //console.log("Access token " + data.accessToken);
        return data.accessToken;
      } else {
        console.error("Access token not available.");
        return null;
      }
    } catch (error) {
      console.error("Failed to fetch access token:", error);
      return null;
    }
  };

  const relinkAccount = async (storedAccessToken) => {
    try {
      const relinkResponse = await fetch(
        "https://api.usecobalt.com/link/token/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            client_id: "ci_live_RRG6m7sZGBWod2xfrhRkw4Ph",
            client_secret: "cs_live_iwoQAkjVRhao7xRbWLLdDUnu",
          },
          body: JSON.stringify({
            user_id: userId,
            org_id: org,
            access_token: storedAccessToken,
          }),
        }
      );

      const relinkData = await relinkResponse.json();
      if (relinkData && relinkData.token) {
        setLinkToken(relinkData.token);
        setIsReadyToInit(true); // Set ready to initialize
      } else {
        console.error("Failed to relink account:", relinkData.message);
        alert(
          "Unable to setup your integration at this time. Please try again or contact Knowtex support. Error details: " +
            relinkData.message
        );
      }
    } catch (err) {
      console.error("Failed to relink account:", err);
      alert(
        "Unable to setup your integration at this time. Please try again or contact Knowtex support. Error details: " +
          err.message
      );
    } finally {
      setButtonStatus(false); // Stop loading after the fetch request
    }
  };

  /*
    Begin CobaltLink Code
  */

  // Setup the onSuccess callback for CobaltLink
  const onSuccess = useCallback((public_token) => {
    //console.log("got the public_token: ", public_token);
    setPublicToken(public_token);
    setButtonStatus(false);

    //Exchange Public token for Access token
    getAccessToken(public_token);
  }, []);

  // Setup the Cobalt Link with the token
  const { init, error, loading } = useCobaltLink({
    token: linkToken,
    onSuccess,
  });

  useEffect(() => {
    if (isReadyToInit && linkToken && !publicToken) {
      init();
    }
  }, [isReadyToInit, linkToken, init]);

  /*
    End CobaltLink Code
  */

  const getAccessToken = async (pToken) => {
    try {
      const response = await fetch(
        `https://api.usecobalt.com/link/token/exchange?public_token=${encodeURIComponent(
          pToken
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            client_id: "ci_live_RRG6m7sZGBWod2xfrhRkw4Ph",
            client_secret: "cs_live_iwoQAkjVRhao7xRbWLLdDUnu",
          },
        }
      );
      const data = await response.json();
      if (data && data.token) {
        console.log("got the access token");
        setAccessToken(data.token);

        // Insert access token into postgres db
        try {
          const body = { accessToken: data.token };
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/dashboard/insertaccesstoken`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                token: localStorage.token,
              },
              body: JSON.stringify(body),
            }
          );

          const parseRes = await response.json();
          console.log(parseRes);
        } catch (err) {
          console.error("Error inserting access token into db:", err.message);
          setShowIntegrationModal(true);
          setButtonStatus(false);
          // alert(
          //   "Unable to setup your integration at this time. Please try again or contact Knowtex support. Error details: " +
          //   err.message
          // );
        }
      } else {
        console.error("Failed to obtain access token:", data.message);
        setShowIntegrationModal(true);
        setButtonStatus(false);
        // alert(
        //   "Unable to setup your integration at this time. Please try again or contact Knowtex support. Error details: " +
        //   data.message
        // );
      }
    } catch (err) {
      console.error("Failed to setup integration:", err);
      setShowIntegrationModal(true);
      setButtonStatus(false);
    }
  };

  return (
    <div className="flex flex-col">
      <SideBarV2
        isOpen={isSidebarOpen}
        onClose={closeSidebar}
        setAuth={setAuth}
      />
      <button
        onClick={openSidebar}
        className="text-5xl text-accent2 text-left ml-10 mt-10 rounded desktop:hidden"
      >
        ☰
      </button>
      <div className="flex flex-col transition-margin ml-10 tablet:mt-3 desktop:ml-dashboard-left">
        <div className="flex mt-5 text-4xl font-bold text-accent1">
          <h1>Settings</h1>
        </div>
        <div className="flex mt-5 text-2xl font-bold text-accent1">
          <h2>{userName}'s Integrations</h2>
        </div>
        <div className="mt-3 mb-3">
          <button
            onClick={getPublicToken}
            disabled={loading}
            className="px-4 py-2 bg-accent2 text-white rounded hover:bg-accent1"
          >
            {buttonStatus ? "Loading..." : "Set Up Integration"}
          </button>
        </div>
        {showIntegrationModal && (
          <IntegrationModal setShowIntegrationModal={setShowIntegrationModal} />
        )}
        <div>
          {subscriptionId && (
            <button
              onClick={handleOpenCancelClick}
              disabled={loading}
              className="px-4 py-2 bg-accent2 text-white rounded hover:bg-accent1"
            >
              {buttonStatus ? "Loading..." : "Cancel Subscription"}
            </button>
          )}
        </div>
        {showCancellationModal ? (
          <CancelSubscriptionModal
            userId={userId}
            subscriptionId={subscriptionId}
            setSubscriptionId={setSubscriptionId}
            setShowCancellationModal={setShowCancellationModal}
            setShowInformationModal={setShowInformationModal}
          />
        ) : null}
        {!subscriptionId && subscriptionEndDate && (
          <div className="mt-3 mb-3">
            <button
              onClick={handleOpenInformationClick}
              disabled={loading}
              className="px-4 py-2 bg-accent2 text-white rounded hover:bg-accent1"
            >
              {buttonStatus ? "Loading..." : "Subscription Info"}
            </button>
          </div>
        )}
        {showInformationModal && (
          <InformationModal
            setShowInformationModal={setShowInformationModal}
            subscriptionEndDate={formattedSubEndDate}
          />
        )}
      </div>
    </div>
  );
}
