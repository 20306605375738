const IntegrationModal = ({ setShowIntegrationModal }) => {
  const handleCancelClick = () => {
    setShowIntegrationModal(false);
  }

  return (
    <div className="fixed bg-gray-800 bg-opacity-80 inset-0 flex items-center justify-center z-50">
      <div className="relative flex flex-col items-center justify-center bg-white p-6 rounded-lg shadow-lg">
        <button onClick={handleCancelClick} className="text-2xl absolute top-3 right-5 font-extrabold hover:text-accent1">
          X
        </button>
        <svg className="w-[80px] h-[80px] text-accent2 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
          <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z" clipRule="evenodd" />
        </svg>
        <h2 className="flex flex-col text-2xl text-accent1 font-black mt-2 mb-4">
          <span className="text-center">
            Integration Support
          </span>
        </h2>
        <p className="flex flex-col text-accent1 mb-4 text-center border-b-2 pb-4">
          <span>
            You currently do not have access to integration support.
          </span>
          <p className="mx-auto mt-[1.5rem] text-accent1 text-center">Questions or concerns? Please message our live chat support.</p>
        </p>
        <button className="bg-accent2 hover:bg-accent1 text-white py-2 px-4 rounded" onClick={() => window.Atlas.chat.openWindow()} >Live Chat</button>
      </div>
    </div>
  );
};

export default IntegrationModal;
