import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CancelSubscriptionModal = ({ userId, subscriptionId, setSubscriptionId, setShowCancellationModal, setShowInformationModal }) => {
  const navigate = useNavigate();

  const handleCancellationClick = async () => {
    navigate("/unsubscribe")
  }

  const handleCancelClick = () => {
    setShowCancellationModal(false)
  }

  return (
    <div className="fixed text-accent1 bg-gray-800 bg-opacity-80 inset-0 flex items-center justify-center z-50">
      <div className="relative flex flex-col items-center justify-center bg-white p-6 rounded-lg shadow-lg">
        <button onClick={handleCancelClick} className="text-2xl absolute top-3 right-5 font-extrabold hover:text-accent1">
          X
        </button>
        <svg className="w-[80px] h-[80px] text-ktx-red dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
          <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z" clipRule="evenodd" />
        </svg>
        <h2 className="flex flex-col text-2xl font-black mt-2 mb-4">
          <span className="text-center">
            Confirm Subscription Cancellation
          </span>
        </h2>
        <p className="flex flex-col mb-4 text-center">
          <span>
            Are you sure you want to unsubscribe?
          </span>
        </p>
        <ul className="list-disc mb-[2rem] border-b-2 pb-[2rem]"><p className="font-semibold">What you'll be missing:</p>
          <li className="ml-[2rem]">Live capture of visit details automatically</li>
          <li className="ml-[2rem]">Potential for time save (during and after the clinic day)</li>
        </ul>
        <div className="flex mt-3 ">
          <button onClick={handleCancellationClick} className="bg-ktx-red hover:bg-red-600 text-white py-2 px-4 rounded">
            End Subscription
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscriptionModal;
