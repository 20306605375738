import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import axios from "axios";

export default function LogoutButton({ setAuth }) {
  const navigate = useNavigate();

  const logout = (e) => {
    e.preventDefault();

    // get user_id from token
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];
    // update the logout
    // User log in failed
    window.gtag("event", "user_logged_out", {
      app_name: "Knowtex-Webapp",
      screen_name: "DashBoard",
      job_name: user_id,
    });

    // Define the event
    const userEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_logged_out",
        userId: user_id,
        screenName: "DashBoard",
        timestamp: new Date().toISOString(),
      },
    };
    const endpointUrl =
      "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";
    axios
      .post(endpointUrl, userEvent)
      .then((response) => {
        //console.log("Event sent successfully:", response.data);
      })
      .catch((error) => {
        //console.error("Failed to send event:", error);
      });

    //console.log("User:%s logged out \n", user_id);

    localStorage.removeItem("token");
    localStorage.removeItem("date");
    
    setAuth(false);
    navigate("/login");
    toast.success("Logout successful");
  };

  return (
    <div
      className="flex flex-col w-full my-16 items-center"
      data-testid="logout-button"
    >
      <button
        type="button"
        className="w-[70%] laptop:text-xl text-center h-button-h desktop:text-2xl text-lg font-medium text-white hover:bg-accent1 bg-dark-purple rounded-lg "
        onClick={logout}
      >
        Logout
      </button>
    </div>
  );
}
