import React, { useState, useEffect } from "react";
import logo from "../../assets/logo_sept.png";
import css from "../login.module.css";
import { useNavigate } from "react-router-dom";

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const [wasSubscribed, setWasSubscribed] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("subscription_ends")) {
      setWasSubscribed(true);
    }
  }, [])

  const handleSubscriptionClick = () => {
    navigate("/subscribe");
  };

  return (
    <>
      {
        !wasSubscribed ?
          (
            <div className="flex justify-center items-center h-dvh w-dvw bg-login-background">
              <div
                className=" bg-white flex flex-col rounded-md justify-center z-0 items-center shadow-knowtex-shadow"
              >
                <div className="text-center z-0 w-[60%] min-w-[240px]">
                  <img
                    className={`${css.logo}`}
                    src={logo}
                    lt="logo" />
                </div>
                <div className="bg-white p-8 rounded shadow-md text-center z-0" >
                  <h1 className="text-accent1 text-2xl font-bold mb-4 sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                    Your 7 day trial has ended
                  </h1>
                  <p className="mb-6 text-accent1">
                    To continue using Knowtex, please subscribe by clicking the
                    button below.
                  </p>
                  <button
                    onClick={handleSubscriptionClick}
                    className="bg-accent2 text-white px-4 py-2 rounded hover:bg-purple2 cursor-pointer"
                  >
                    Subscribe
                  </button>
                  <p className="mx-auto mt-[1.5rem] text-accent1 text-center w-[60%] min-w-[240px]"><button className="hover:text-accent1 text-accent2 font-semibold"  onClick={() => window.Atlas.chat.openWindow()} >Questions or concerns?</button> Please reach us at <a className="hover:text-accent1 text-accent2 font-semibold" href="mailto:support@knowtex.ai">support@knowtex.ai</a> or call <a className="hover:text-accent1 text-accent2 font-semibold" href="tel:+1-323-679-6957">+1 (323) 679-6957</a></p>
                </div>
              </div>
            </div>
          )
          :
          (
            <div className="flex justify-center items-center h-dvh w-dvw bg-login-background">
              <div
                className="bg-white flex flex-col rounded-md justify-center z-0 items-center shadow-knowtex-shadow"
              >
                <div className="text-center z-0 w-[60%] min-w-[240px]">
                  <img
                    className={`${css.logo}`}
                    src={logo}
                    lt="logo" />
                </div>
                <div className="bg-white p-8 rounded shadow-md text-center z-0" >
                  <h1 className="text-accent1 text-2xl font-bold mb-4 sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                    Your paid subscription period has ended
                  </h1>
                  <p className="mb-6 text-accent1">
                    To continue using Knowtex, please re-subscribe by clicking the
                    button below.
                  </p>
                  <button
                    onClick={handleSubscriptionClick}
                    className="bg-accent2 text-white px-4 py-2 rounded hover:bg-accent1 cursor-pointer"
                  >
                    Subscribe
                  </button>
                  <p className="mx-auto mt-[1.5rem] text-accent1 text-center w-[60%] min-w-[240px]"><button className="hover:text-accent1 text-accent2 font-semibold" onClick={() => window.Atlas.chat.openWindow()} >Questions or concerns?</button> Please reach us at <a className="hover:text-accent1 text-accent2 font-semibold" href="mailto:support@knowtex.ai">support@knowtex.ai</a> or call <a className="hover:text-accent1 text-accent2 font-semibold" href="tel:+1-323-679-6957">+1 (323) 679-6957</a></p>
                </div>
              </div>
            </div>
          )
      }
    </>
  );
};

export default SubscriptionPage;
