import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import RegionSelector from "./FormFields/RegionSelector";

const RegistrationForm = ({ setAuth }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    organization: "",
    qa: "Automatic",
    modelType: "English",
    turnaroundTime: "Real Time",
    specialty: "",
    phoneNumber: "",
    region: ""
  });

  const [errors, setErrors] = useState({});

  const {
    name,
    email,
    password,
    organization,
    qa,
    modelType,
    turnaroundTime,
    specialty,
    phoneNumber,
    region
  } = formData;
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSpecialtyChange = (e) => {
    const selectedSpecialty = e.target.value;
    setFormData({ ...formData, specialty: selectedSpecialty });
    setErrors({ ...errors, specialty: "" });
  };

  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;
    setFormData({ ...formData, region: selectedRegion });
    setErrors({ ...errors, region: "" });
  };

  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();

  const validateForm = () => {
    let formErrors = {};
    if (!name) formErrors.name = "Full Name is required";
    if (!email) formErrors.email = "Email Address is required";
    if (!password) formErrors.password = "Password is required";
    if (!phoneNumber) formErrors.phoneNumber = "Phone Number is required";
    if (!organization) formErrors.organization = "Organization is required";
    if (!specialty || specialty === "select")
      formErrors.specialty = "Specialty is required";
    if (!region || region === 'select') 
      formErrors.region = "Region is required";
    return formErrors;
  };

  const handleNext = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    if (!executeRecaptcha) {
      toast.error("reCAPTCHA not ready");
      return;
    }

    const token = await executeRecaptcha("register_form");
    if (!token) {
      toast.error("reCAPTCHA verification failed");
      return;
    }

    try {
      const body = {
        name,
        email,
        password,
        organization,
        qa,
        modelType,
        turnaroundTime,
        specialty,
        phoneNumber,
        region
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      const parseRes = await response.json();
      if (parseRes.token) {
        localStorage.setItem("token", parseRes.token);
        setAuth(true);
        toast.success("Registered Successfully");
        navigate("/dashboard");
      } else {
        setAuth(false);
        toast.error(parseRes);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <div className="flex flex-col min-w-[16rem] max-w-[32rem] mx-auto">
      <div className="flex flex-col">
        <div className="text-4xl text-light-grey font-bold tracking-wider mb-2">
          Register
        </div>
        <div className="italic text-light-grey font-extralight">
          * Indicates Required Field
        </div>
      </div>
      <form onSubmit={handleNext}>
        <div className="relative flex flex-row justify-center mb-4">
          <input
            placeholder="*Full Name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`pl-10 pr-4 py-2 border w-full focus:placeholder-white rounded-md text-light-grey font-light text-xl shadow-md ${
              errors.name ? "border-red-500" : ""
            }`}
            required
          />
          <svg
            className="absolute w-6 h-6 top-1/2 left-3 transform -translate-y-1/2 text-light-grey mobile:w-5 mobile:h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name}</p>
          )}
        </div>
        <div className="relative flex flex-row justify-center mb-4">
          <input
            placeholder="*Organization/Clinic Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`pl-10 pr-4 py-2 border w-full rounded-md focus:placeholder-white text-light-grey font-light text-xl shadow-md ${
              errors.email ? "border-red-500" : ""
            }`}
            required
          />
          <svg
            className="absolute w-6 h-6 text-light-grey top-1/2 left-3 transform -translate-y-1/2 mobile:w-5 mobile:h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth="2"
              d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
            />
          </svg>
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email}</p>
          )}
        </div>
        <div className="relative flex flex-row justify-center mb-4">
          <input
            placeholder="*Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className={`pl-10 pr-4 py-2 border w-full rounded-md focus:placeholder-white text-light-grey font-light text-xl shadow-md ${
              errors.password ? "border-red-500" : ""
            }`}
            required
          />
          <svg
            className="absolute w-7 h-7 text-light-grey top-1/2 left-3 transform -translate-y-1/2 mobile:w-6 mobile:h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 14v3m-3-6V7a3 3 0 1 1 6 0v4m-8 0h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Z"
            />
          </svg>
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">{errors.password}</p>
          )}
        </div>
        <div className="relative flex flex-row justify-center mb-4">
          <input
            placeholder="*Phone Number"
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className={`pl-10 pr-4 py-2 border w-full rounded-md text-light-grey font-light text-xl shadow-md ${
              errors.phoneNumber ? "border-red-500" : ""
            }`}
            required
          />
          <svg
            className="absolute w-7 h-7 text-light-grey top-1/2 left-3 transform -translate-y-1/2 mobile:w-6 mobile:h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"
            />
          </svg>
          {errors.phoneNumber && (
            <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>
          )}
        </div>
        <div className="relative flex flex-row justify-center mb-4">
          <input
            placeholder="*Organization"
            type="text"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            className={`pl-10 pr-4 py-2 border w-full rounded-md text-light-grey font-light text-xl shadow-md ${
              errors.organization ? "border-red-500" : ""
            }`}
            required
          />
          <svg
            className="absolute w-7 h-7 text-light-grey top-1/2 left-3 transform -translate-y-1/2 mobile:w-6 mobile:h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 4h12M6 4v16M6 4H5m13 0v16m0-16h1m-1 16H6m12 0h1M6 20H5M9 7h1v1H9V7Zm5 0h1v1h-1V7Zm-5 4h1v1H9v-1Zm5 0h1v1h-1v-1Zm-3 4h2a1 1 0 0 1 1 1v4h-4v-4a1 1 0 0 1 1-1Z"
            />
          </svg>
          {errors.organization && (
            <p className="text-red-500 text-sm mt-1">{errors.organization}</p>
          )}
        </div>
        <div className="relative flex flex-col justify-center mb-4">
          <RegionSelector handleRegionChange={handleRegionChange} formData={formData} errors={errors} />
        </div>
        <div className="relative flex flex-col justify-center mb-4">
          <select
            name="specialty"
            value={formData.specialty}
            onChange={handleSpecialtyChange}
            className={`pl-10 pr-4 py-2 border w-full rounded-md text-light-grey font-light text-xl shadow-md ${
              errors.specialty ? "border-red-500" : ""
            }`}
            required
          >
            <option value="" disabled>*Select Specialty</option>
            <option value="Orthopedics">Orthopedics</option>
            <option value="Oncology">Oncology</option>
            <option value="Podiatry">Podiatry</option>
            <option value="Radiation Oncology">Radiation Oncology</option>
            <option value="Dentist">Dentist</option>
            <option value="Obstetrics and Gynecology">
              Obstetrics and Gynecology
            </option>
            <option value="Plastic Surgery">Plastic Surgery</option>
            <option value="Endocrinology">Endocrinology</option>
            <option value="Pediatric Neurology">Pediatric Neurology</option>
            <option value="Urology">Urology</option>
            <option value="Primary Care">Primary Care</option>
            <option value="Orthopedics">Other</option>
            
          </select>
          <div className="pl-2 mt-2 text-light-grey">
            Don't see your specialty?{" "}
            <a
              href="https://www.knowtex.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark-purple underline"
            >
              Contact us
            </a>{" "}
            for more information.
          </div>

          {errors.specialty && (
            <p className="text-red-500 text-sm mt-1">{errors.specialty}</p>
          )}
        </div>
        <div className="terms-and-conditions mt-2 mb-3 ml-1">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="agreeCheckbox"
              name="agreeCheckbox"
              className="border rounded-sm shadow-md h-6 w-6 hover:cursor-pointer"
              required
            />
            <label
              htmlFor="agreeCheckbox"
              className="text-md text-light-grey ml-4"
            >
              I agree to Knowtex's terms and conditions, privacy policy, and opt-in for communication
            </label>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <button
            className="w-full border rounded-lg px-4 py-2 bg-indigo-600 text-white text-xl font-semibold hover:bg-indigo-700 transition duration-300 ease-in-out"
            type="submit"
          >
            Next
          </button>
          <button
            onClick={handleCancel}
            className="w-full mt-3 text-light-grey hover:text-indigo-700"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
