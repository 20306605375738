import { useState, useRef, useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";

import { DotWave } from "@uiball/loaders";
import { useLocation, useNavigate } from "react-router-dom";

import bars from "../../assets/icons8-audio-wave.gif";
import micicon from "../../assets/icons8-microphone-64.png";
import mic from "../../assets/icon _mic_.png";

import css from "./record.module.css";

const mimeType = "audio/mp3";
let chunkNumber = 0;

const AudioRecorder = ({ patientID, doctorName, setIsAudio, isAudio }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const chunksRef = useRef([]);

  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [audio, setAudio] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioURLs, setAudioURLs] = useState([]);
  const [readyToUpload, setReadyToUpload] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [showUploadBanner, setShowUploadBanner] = useState(false);

  const [conversationID, setConversationID] = useState(null);

  const [hasRecorded, setHasRecorded] = useState(false);

  const [diasbleUpload, setDisableUpload] = useState(false);

  //timer
  const [elapsedTime, setElapsedTime] = useState(0); // Recording duration in seconds
  const [timerInterval, setTimerInterval] = useState(null); // Timer interval reference

  //upload time start for streamlit dashboard analytics
  const [uploadTimeStart, setUploadTimeStart] = useState(0);

  useEffect(() => {
    // Try to get microphone permission immediately upon mounting
    getMicrophonePermission();

    // Cleanup function to stop the stream when unmounting
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(mediaStream);
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    setIsUploaded(false);
    setIsLoading(false);
    setIsAudio(true);
    setRecordingStatus("recording");
    chunkNumber = 0;

    // Clear the previous timer interval if it exists
    if (timerInterval) {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }

    // Start the timer
    const timer = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);
    setTimerInterval(timer);

    var id = await createConversationID();
    setConversationID(id);
    //setConversationID("test/9/19/2023-02:11:49PM-6907");
    // Request access to the microphone
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = (e) =>
          handleDataAvailable(e, id); // pass the conversation ID here
        mediaRecorder.current.start();

        // Stop the MediaRecorder and slice the audio every 30 seconds
        setInterval(() => {
          if (
            mediaRecorder.current &&
            mediaRecorder.current.state === "recording"
          ) {
            mediaRecorder.current.stop();
            mediaRecorder.current.start();
          }
        }, 30000);
      })
      .catch((err) => console.log(err));
  };

  const handleDataAvailable = (e, conversationID) => {
    chunksRef.current.push(e.data);

    // send each 30 second chunk to s3
    const blob = new Blob([e.data], { type: "audio/mp3" });
    const fileName = `${conversationID}-chunk_${chunkNumber}.mp3`;
    //console.log("filename: " + fileName);
    var file = new File([blob], fileName, {
      type: mimeType,
    });
    //console.log(file);
    handleAudioFile(file);
    chunkNumber++;
  };

  const handleAudioFile = (file) => {
    //changeStatusToUploading();

    let fileType = file.type;

    let fSize = file.size / 1000000; //calculate file size
    // this.setState({ fileSize: fSize }, () => {
    //   //console.log("file size" + this.state.fileSize);
    // });

    /**
    We pass the audio file as param to handleaudiofile() from which we 
    collect the file name and type and pass it to function in the backend 
    sign_s3 using Axios.
     */

    axios
      .post(`${process.env.REACT_APP_API_URL}/sign_s3_realtime`, {
        fileName: file.name,
        fileType: fileType,
      })
      .then((response) => {
        var returnData = response.data.data.returnData;
        var signedRequest = returnData.signedRequest;
        // console.log("Received a signed request " + signedRequest);
        var options = {
          headers: {
            "Content-Type": fileType,
          },
        };
        /**
        We use a pre-signed URL to audio the audio which is passed to sign_s3 function
        along with the file and header which uploads the audio to s3 using a pre-signed
        URL.
        */
        axios
          .put(signedRequest, file, options)
          .then((result) => {
            // this.setState({ audio: url });

            // this.setState({
            //   isUploading: false,
            //   isUploaded: true,
            // });

            //console.log("upload time end " + Date.now());

            let uploadTimeDiff = Date.now() - uploadTimeStart;
            let uploadTimeInSeconds = uploadTimeDiff / 1000; // Convert to seconds

            const decoded_token = jwt_decode(localStorage.token);
            const user_id = decoded_token["user"]["id"];

            const userEvent = {
              env: process.env.REACT_APP_API_URL.includes("localhost")
                ? "local"
                : "prod",
              service: "knowtex-webApp",
              event: {
                eventName: "audio_finished_uploading",
                userId: user_id,
                patientID: patientID,
                screenName: "Record Page",
                timestamp: new Date().toISOString(),
                uploadTime: uploadTimeInSeconds,
                fileSize: fSize,
              },
            };
            const endpointUrl =
              "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";
            axios
              .post(endpointUrl, userEvent)
              .then((response) => {
                // console.log("Event sent successfully:", response.data);
              })
              .catch((error) => {
                // console.error("Failed to send event:", error);
              });
            //console.log("User:%s log in succeed",email)
          })
          .catch((error) => {
            alert("This is the put signed request error: " + error);
            //toast.error("Unable to upload audio");
          });
      })
      .catch((error) => {
        alert("This is the response error message: " + error);
      });
  };

  const changeStatusToUploading = async () => {
    try {
      const status = "Uploading Audio";
      //console.log(status);
      const body = { status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/notes/status/${patientID}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
      //console.log("Uploading Audio Status changed");
    } catch (err) {
      console.error(err.message);
    }
  };

  const changeStatusToProcessing = async () => {
    try {
      const status = "Processing Audio";
      const body = { status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/notes/status/${patientID}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
    } catch (err) {
      console.error(err.message);
    }
  };

  const changeStatusToReadyToRecord = async () => {
    try {
      const status = "Ready to Record";
      //console.log(status);
      const body = { status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/notes/status/${patientID}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
      //console.log("Uploading Audio Status changed");
    } catch (err) {
      console.error(err.message);
    }
  };

  //{doctor_name}/{month}/{day}/{year}-{doctor_click_record_time}-{patient_id}-chunk_{chunk_id}.mp3
  const createConversationID = async () => {
    var uploadTimeStart = await getTimeStamp();
    var conversationID =
      doctorName.replace(/\s+/g, "") + "/" + uploadTimeStart + "-" + patientID;
    //console.log(conversationID);
    return conversationID;
  };

  const getTimeStamp = async () => {
    var d = new Date();
    var options = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    var timeStamp = d.toLocaleDateString("en-US", options);
    var formattedTimeStamp = timeStamp.replace(/,/g, "-").replace(/\s+/g, ""); //removes comma and spaces and adds '-' between date and time
    return formattedTimeStamp;
  };

  //pause functionality
  const togglePauseRecording = () => {
    if (mediaRecorder.current && recordingStatus === "recording") {
      mediaRecorder.current.pause();
      setRecordingStatus("paused");
      clearInterval(timerInterval); // Pause the timer
    } else if (mediaRecorder.current && recordingStatus === "paused") {
      mediaRecorder.current.resume();
      setRecordingStatus("recording");
      // Resume the timer
      const interval = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
      setTimerInterval(interval);
    }
  };

  const handleShortRecording = () => {
    if (mediaRecorder.current) {
      setRecordingStatus("paused");
      mediaRecorder.current.pause();
    }
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    setHasRecorded(true);
    setIsLoading(true);

    if (mediaRecorder.current) {
      mediaRecorder.current.onstop = handleStop;
      mediaRecorder.current.stop();
    }

    // Clear the timer
    if (timerInterval) {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
    //setReadyToUpload(true);

    setTimeout(function () {
      setIsLoading(false);
      setReadyToUpload(true);
    }, 2000);
  };

  const handleStop = () => {
    chunksRef.current.forEach((chunk) => {
      const blob = new Blob([chunk], { type: "audio/mp3" });
      setAudioURLs((prevAudioURLs) => [
        ...prevAudioURLs,
        URL.createObjectURL(blob),
      ]);
    });
    chunksRef.current = [];

    //check if the audio recording is less than 2 seconds. if yes, show alert and set state var to true.
    if (elapsedTime <= 2) {
      window.alert(
        "Audio recording must be longer than 2 seconds to be uploaded. Please delete this recording and try again."
      );
      setDisableUpload(true);
      return;
    }

    setDisableUpload(false);
  };

  async function uploadAudio() {
    logUploadEvent();
    setIsUploading(true);

    try {
      //console.log(conversationID);
      const endpointUrl = process.env.REACT_APP_MERGE_TRANSCRIPT_API_URL;
      const data = postData(endpointUrl);

      console.log("Response of sending finished recording message", data);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setIsLoading(false);
      setIsUploading(false);
      setIsUploaded(true);
      changeStatusToProcessing();
      resetRecording();
    }
    setIsUploaded(true);
  }

  async function postData(
    url = "",
    data = { conversation_id: conversationID },
    method = "POST"
  ) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: method, // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      // Add the 'token' as a header to allow access to backend

      // headers: {
      //   token: window.localStorage.getItem("token"),
      // },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const logUploadEvent = () => {
    // get user_id from token
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];
    // User clicked upload
    window.gtag("event", "user_clicked_upload", {
      app_name: "Knowtex-Webapp",
      screen_name: "Record Page",
      job_name: user_id,
    });

    //start calculating upload time
    setUploadTimeStart(Date.now());

    // Cloudwatch logging event for user upload
    const userEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_clicked_upload",
        userId: user_id,
        patientID: patientID,
        screenName: "Record Page",
        timestamp: new Date().toISOString(),
      },
    };
    const endpointUrl =
      "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";
    axios
      .post(endpointUrl, userEvent)
      .then((response) => {
        // console.log("Event sent successfully:", response.data);
      })
      .catch((error) => {
        // console.error("Failed to send event:", error);
      });
    //console.log("User:%s log in succeed",email)
  };

  //deletes audio and resets back to original state
  const resetRecording = () => {
    if (mediaRecorder.current) {
      // Detach the event handler
      mediaRecorder.current.ondataavailable = null;

      if (mediaRecorder.current.state === "recording") {
        mediaRecorder.current.stop();
      }

      // If you have tracks to close, you would do it here as well.
      if (mediaRecorder.current.stream) {
        mediaRecorder.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    }
    // If there are any audio URLs, revoke them to free up memory.
    if (audioURLs.length > 0) {
      audioURLs.forEach((url) => URL.revokeObjectURL(url));
    }

    // Clear the timer interval if it's set.
    if (timerInterval) {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }

    // Reset the state and other variables.
    chunksRef.current = [];
    setAudio(null);
    setStream(null);
    setRecordingStatus("inactive");
    setAudioChunks([]);
    setAudioURLs([]);
    setHasRecorded(false);
    setIsAudio(false);
    setConversationID(null);
    setIsUploading(false);
    setElapsedTime(0);
    chunkNumber = 0;
    setReadyToUpload(false);
    setIsLoading(false);
    setIsUploaded(false);
    setUploadTimeStart(0);
  };

  // Utility function to format seconds into HH:MM:SS
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    // Format to "02" instead of "2"
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(secs).padStart(2, "0");

    return hours > 0
      ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
      : `${formattedMinutes}:${formattedSeconds}`;
  };

  const iconButton = {
    height: "3.5rem",
    width: "3.5rem",
  };

  useEffect(() => {
    if (isUploaded) {
      setShowUploadBanner(true);
      // Hide the banner after 5 seconds, optional
      const timer = setTimeout(() => {
        setShowUploadBanner(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isUploaded]);

  return (
    <div
      style={{
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh", // Ensures full height of the viewport
      }}
    >
      {recordingStatus === "recording" ||
      recordingStatus === "paused" ||
      hasRecorded ? (
        <div className={css.timer}>{formatTime(elapsedTime)}</div>
      ) : null}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {!permission ? (
          <button onClick={getMicrophonePermission} className={css.record}>
            Get Microphone
          </button>
        ) : null}

        <img
          src={recordingStatus === "recording" ? bars : mic}
          width="200"
          height="200"
          alt="bars"
          className="mb-4"
        />
        {permission && recordingStatus === "inactive" && !hasRecorded ? (
          <div className="flex flex-col">
            <button
              onClick={startRecording}
              className="bg-ktx-green pt-3 pb-3 pl-10 pr-10 rounded-lg mb-3 text-background1 text-2xl"
            >
              Start Recording
            </button>
            {!isAudio ? (
              <button
                className="mt-2 text-2xl text-light-grey "
                onClick={() => navigate(-1)}
              >
                Return Home
              </button>
            ) : (
              <button
                className="mt-2 bg-ktx-green text-light-grey "
                data-bs-toggle="modal"
                data-bs-target={`#areYouSure`}
              >
                Return
              </button>
            )}
          </div>
        ) : null}
        {recordingStatus === "recording" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div
                style={{ color: "rgba(255, 65, 65, 1)" }}
                onClick={stopRecording}
                className="flex flex-col items-center text-xl mr-4 "
              >
                <svg
                  class="w-6 h-6  "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  style={{
                    height: "3.5rem",
                    width: "3.5rem",
                    color: "rgba(255, 65, 65, 1)",
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Stop
              </div>
              <div
                onClick={togglePauseRecording}
                className="flex flex-col items-center text-xl mr-4 text-dark-purple"
              >
                <svg
                  class="text-dark-purple "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  style={iconButton}
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 9v6m4-6v6m7-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                Pause
              </div>
            </div>
            <div
              //onClick={resetRecording}
              data-bs-toggle="modal"
              data-bs-target="#clearAudioModal"
              style={{
                color: "red",
                cursor: "pointer",
                textAlign: "center", // This will center the text within the container
                marginTop: "30px",
              }}
            >
              Delete Audio
            </div>
          </div>
        ) : null}

        {isLoading && (
          <div>
            <h4>Processing Audio</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <DotWave size={47} speed={1} color="black" />
            </div>
          </div>
        )}

        {recordingStatus === "paused" ? (
          <div className="flex flex-col items-center">
            <h1 className="text-3xl text-dark-purple text-bold mb-3 ">
              Recording Paused
            </h1>
            <div className="flex flex-row">
              <div
                style={{ color: "rgba(255, 65, 65, 1)" }}
                onClick={stopRecording}
                className="flex flex-col items-center text-xl mr-4 "
              >
                <svg
                  className="w-6 h-6  "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  style={{
                    height: "3.5rem",
                    width: "3.5rem",
                    color: "rgba(255, 65, 65, 1)",
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Stop
              </div>
              <div
                onClick={togglePauseRecording}
                className="flex flex-col items-center text-xl text-light-grey ml-4"
              >
                <svg
                  className="text-light-grey "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  style={iconButton}
                >
                  <path
                    fill-rule="evenodd"
                    d="M17 6a1 1 0 1 0-2 0v4L8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8L15 14v4a1 1 0 1 0 2 0V6Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Resume
              </div>
            </div>
            <div
              //onClick={resetRecording}
              style={{
                color: "rgba(255, 65, 65, 1)",
                cursor: "pointer",
                textAlign: "center", // This will center the text within the container
                marginTop: "30px",
              }}
              data-bs-toggle="modal"
              data-bs-target="#clearAudioModal"
            >
              Delete Audio
            </div>
          </div>
        ) : null}

        {readyToUpload === true ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <button
              className="flex flex-row text-xl bg-accent2 rounded-lg items-center py-3 justify-center text-background1"
              onClick={uploadAudio}
              disabled={diasbleUpload}
            >
              <svg
                className="w-6 h-6 text-background1 mr-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h3a3 3 0 0 0 0-6s0 0 0 0v-.5A5.5 5.5 0 0 0 7.2 9H7a4 4 0 1 0 0 8h2.2m2.8 2v-9m0 0-2 2m2-2 2 2"
                />
              </svg>
              Upload Audio
            </button>
            <h1 className="text-2xl justify-center flex text-accent1">or</h1>
            <button
              //onClick={resetRecording}
              className={`${css.clear} flex items-center py-2 justify-center text-background1`}
              data-bs-toggle="modal"
              data-bs-target="#clearAudioModal"
            >
              <svg
                className="w-6 h-6 text-background1 mr-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                />
              </svg>
              Delete Audio
            </button>
          </div>
        ) : null}
      </div>
      <div
        className="modal fade"
        id="clearAudioModal"
        tabIndex="-1"
        aria-labelledby="clearAudioModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="clearAudioModal">
                Delete Audio
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this audio file?
            </div>
            <div className={css.footer}>
              <button
                type="button"
                className={css.cancel}
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className={css.delete}
                onClick={resetRecording}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Uploading Progress Bar*/}
      {isUploading === true && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped w-100 progress-bar-animated"
            role="progressbar"
            aria-valuenow="10"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            Uploading
          </div>
        </div>
      )}
      <div
        style={{
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh", // Ensures full height of the viewport
        }}
      >
        {/* Conditional rendering for upload success banner */}
        {showUploadBanner && (
          <div
            style={{
              position: "fixed", // Makes the banner fixed at the top
              top: 0,
              left: 0,
              width: "100%",
              backgroundColor: "#4CAF50", // Example color: green
              color: "white",
              textAlign: "center",
              padding: "10px",
              zIndex: 1000, // Ensure it's above other content
            }}
          >
            Audio uploaded successfully!
          </div>
        )}

        {/* Your existing JSX */}
      </div>
    </div>
  );
};

export default AudioRecorder;
