import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

import "./CheckoutForm.css";
import SubscribeSidebar from "./SubscribeSidebar";

//stripe publishable code for client
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Subscribe({ setIsSubscribed }) {

  const appearance = {
    theme: 'flat',
  };

  const options = {
    appearance,
  };

  return (
    <div className="flex">
      <SubscribeSidebar />
      <div className="h-[100dvh] w-[100dvw] mobile:w-[50dvw] items-center justify-center" id="stripe">
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm setIsSubscribed={setIsSubscribed} />
        </Elements>
      </div>
    </div>
  );
}