import React from "react";
import axios from "axios";

import bars from "../../assets/icons8-audio-wave.gif";
import micicon from "../../assets/icons8-microphone-64.png";
import stopIcon from "../../assets/stop.png";
import pauseIcons from "../../assets/pause.png";
import playIcons from "../../assets/play-button.png";

import css from "./record.module.css";
import jwt_decode from "jwt-decode";

const audioType = "audio/webm";

//Handles recording functionality and uploading to s3
class Record extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: {},
      miliseconds: 0,
      recording: false,
      medianotFound: false,
      audios: [],
      audioBlob: null,
      stream: null,
      timerStart: 0,
      timerTime: 0,
      isListening: false,
      isTimerOn: false,
      isReadyforUpload: false,
      isUploading: false,
      mimeTypeToUseWhenRecording: null,
      isUploaded: false,
      uploadTimeStart: null,
      fileSize: null,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.handleAudioUploadtoS3 = this.handleAudioUploadtoS3.bind(this);
  }

  handleAudioPause(e) {
    e.preventDefault();
    clearInterval(this.timer);
    this.mediaRecorder.pause();

    this.setState({ pauseRecord: true, isListening: false });
  }

  handleAudioStart(e) {
    e.preventDefault();
    this.startTimer();
    this.mediaRecorder.resume();

    this.setState({ pauseRecord: false, isListening: true });
  }

  startTimer() {
    this.setState({
      isTimerOn: true,
      timerTime: this.state.timerTime,
      timerStart: Date.now() - this.state.timerTime,
    });
    this.timer = setInterval(() => {
      this.setState({
        timerTime: Date.now() - this.state.timerStart,
      });
    }, 10);
  }

  async initRecorder() {
    //Determines what browser is being used and what media type is supported and updates the type accordingly
    MediaDevices.getUserMedia =
      MediaDevices.getUserMedia ||
      MediaDevices.webkitGetUserMedia ||
      MediaDevices.mozGetUserMedia ||
      MediaDevices.msGetUserMedia;
    if (navigator.mediaDevices) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      if (MediaRecorder.isTypeSupported("audio/webm; codecs=vp9")) {
        this.setState({ mimeTypeToUseWhenRecording: "audio/webm; codecs=vp9" });
      } else if (MediaRecorder.isTypeSupported("audio/webm")) {
        this.setState({ mimeTypeToUseWhenRecording: "audio/webm" });
      } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
        this.setState({
          mimeTypeToUseWhenRecording: "audio/mp4",
        });
      } else {
        console.error("no suitable mimetype found for this device");
      }

      if (this.mimeTypeToUseWhenRecording) {
        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: this.mimeTypeToUseWhenRecording,
        });
      } else {
        this.mediaRecorder = new MediaRecorder(stream);
      }
      this.chunks = [];
      this.mediaRecorder.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          this.chunks.push(e.data);
        }
      };

      this.stream = stream;
    } else {
      this.setState({ medianotFound: true });
      console.log("Media Devices will work only with SSL.....");
    }
  }

  async startRecording(e) {
    //this.requestWakeLock();
    e.preventDefault();
    // wipe old data chunks
    this.chunks = [];
    await this.initRecorder();
    // start recorder with 10ms buffer
    this.mediaRecorder.start(10);
    this.startTimer();
    // say that we're recording
    this.setState({ recording: true, isListening: true });
    this.props.setIsAudio(true);
  }

  stopRecording(e) {
    e.preventDefault();
    // stop the recorder
    this.mediaRecorder.onstop = () => {
      // convert saved chunks to blob
      const blob = new Blob(this.chunks, {
        type: audioType,
      });
      // generate video URL from blob
      const audioURL = window.URL.createObjectURL(blob);
      // append videoURL to list of saved videos for rendering
      const audios = [audioURL];
      this.setState({
        audios,
        audioBlob: blob,
        recording: false,
        pauseRecord: false,
        isReadyforUpload: true,
        time: {},
        isListening: false,
      });
      this.props.handleAudioStop({
        url: audioURL,
        blob: blob,
        chunks: this.chunks,
        duration: this.state.time,
      });
    };
    this.mediaRecorder.stop();

    if (this.stream.getAudioTracks) {
      const tracks = this.stream.getAudioTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    } else {
      console.log("No Tracks Found");
    }

    clearInterval(this.timer);
  }

  handleReset(e) {
    if (this.state.recording) {
      this.stopRecording(e);
    }
    this.setState(
      {
        time: {},
        miliseconds: 0,
        recording: false,
        medianotFound: false,
        audios: [],
        audioBlob: null,
        timerTime: 0,
        isListening: false,
        isTimerOn: false,
        isReadyforUpload: false,
        isUploading: false,
        uploadTimeStart: null,
        fileSize: null,
      },
      () => {
        this.props.handleReset(this.state);
      }
    );
    this.props.setIsAudio(false);
  }

  handleAudioUploadtoS3(blob) {
    this.props.setIsAudio(false);

    // logging
    // get user_id from token
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];
    // User clicked upload
    window.gtag("event", "user_clicked_upload", {
      app_name: "Knowtex-Webapp",
      screen_name: "Record Page",
      job_name: user_id,
    });

    //start calculating upload time
    this.setState({ uploadTimeStart: Date.now() }, () => {
      //console.log("upload time start " + this.state.uploadTimeStart);
    });

    // Define the event
    const userEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_clicked_upload",
        userId: user_id,
        patientID: this.props.patientID,
        screenName: "Record Page",
        timestamp: new Date().toISOString(),
      },
    };
    const endpointUrl =
      "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";
    axios
      .post(endpointUrl, userEvent)
      .then((response) => {
        // console.log("Event sent successfully:", response.data);
      })
      .catch((error) => {
        // console.error("Failed to send event:", error);
      });
    //console.log("User:%s log in succeed",email)

    this.setState({ isUploading: true });
    var d = new Date();
    var options = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    var formattedDate = d.toLocaleDateString("en-US", options);

    var file = new File([blob], formattedDate, {
      type: audioType,
    });

    //removes spaces between name
    var doctorName = this.props.name.replace(/\s+/g, "");
    this.handleAudioFile(file, doctorName);
  }

  handleAudioFile(ev, doctorName) {
    this.changeStatusToUploading();

    let file = ev;
    //This sets the file name to be [doctor name]/[month]/[day]/[year]-HH:MM:SSAM/PM-[patientid]
    let fileNameRemoveComma = ev.name.replace(/,/g, "-");
    let fileNameRemoveSpaces =
      doctorName + "/" + fileNameRemoveComma.replace(/\s+/g, "");
    //have to append unique patientID to file name so we can identify which patient the audio recording belongs to
    let finalFileName = fileNameRemoveSpaces + "-" + this.props.patientID;
    let fileType = ev.type;

    let fSize = ev.size / 1000000; //calculate file size
    this.setState({ fileSize: fSize }, () => {
      //console.log("file size" + this.state.fileSize);
    });

    /**
    We pass the audio file as param to handleaudiofile() from which we 
    collect the file name and type and pass it to function in the backend 
    sign_s3 using Axios.
     */
    axios
      .post(`${process.env.REACT_APP_API_URL}/sign_s3`, {
        fileName: finalFileName,
        fileType: fileType,
      })
      .then((response) => {
        var returnData = response.data.data.returnData;
        var signedRequest = returnData.signedRequest;
        var url = returnData.url;
        this.setState({ url: url });
        console.log("Received a signed request " + signedRequest);
        var options = {
          headers: {
            "Content-Type": fileType,
          },
        };
        /**
        We use a pre-signed URL to audio the audio which is passed to sign_s3 function
        along with the file and header which uploads the audio to s3 using a pre-signed
        URL.
        */
        axios
          .put(signedRequest, file, options)
          .then((result) => {
            this.setState({ audio: url });

            this.setState({
              isUploading: false,
              isUploaded: true,
            });

            //console.log("upload time end " + Date.now());

            let uploadTimeDiff = Date.now() - this.state.uploadTimeStart;
            let uploadTimeInSeconds = uploadTimeDiff / 1000; // Convert to seconds

            this.changeStatusToProcessing();

            const decoded_token = jwt_decode(localStorage.token);
            const user_id = decoded_token["user"]["id"];

            const userEvent = {
              env: process.env.REACT_APP_API_URL.includes("localhost")
                ? "local"
                : "prod",
              service: "knowtex-webApp",
              event: {
                eventName: "audio_finished_uploading",
                userId: user_id,
                patientID: this.props.patientID,
                screenName: "Record Page",
                timestamp: new Date().toISOString(),
                uploadTime: uploadTimeInSeconds,
                fileSize: this.state.fileSize,
              },
            };
            const endpointUrl =
              "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";
            axios
              .post(endpointUrl, userEvent)
              .then((response) => {
                // console.log("Event sent successfully:", response.data);
              })
              .catch((error) => {
                // console.error("Failed to send event:", error);
              });
            //console.log("User:%s log in succeed",email)

            this.handleReset();
          })
          .catch((error) => {
            alert("This is the put signed request error: " + error);
            //toast.error("Unable to upload audio");
          });
      })
      .catch((error) => {
        alert("This is the response error message: " + error);
      });
  }

  changeStatusToUploading = async () => {
    try {
      const status = "Uploading Audio";
      const body = { status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/notes/status/${this.props.patientID}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
      console.log("Uploading Audio Status changed");
    } catch (err) {
      console.error(err.message);
    }
  };

  changeStatusToProcessing = async () => {
    try {
      const status = "Processing Audio";
      const body = { status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/notes/status/${this.props.patientID}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
    } catch (err) {
      console.error(err.message);
    }
  };

  render() {
    const { recording, audios, medianotFound, pauseRecord, timerTime } =
      this.state;
    const { showUIAudio, title, audioURL, disableFullUI } = this.props;
    let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
    if (disableFullUI) {
      return null;
    }

    return (
      <div>
        <div className="text-center mt-5">
          {!recording ? <h5>{title}</h5> : <h5>Now Recording</h5>}
        </div>
        <p></p>

        <div>
          {audioURL !== null && showUIAudio ? (
            <audio controls className="container-fluid">
              <source src={audios[0]} type="audio/ogg" />
              <source src={audios[0]} type="audio/mpeg" />
            </audio>
          ) : null}
        </div>
        <div>
          <div className="text-center mb-3">
            {this.state.isListening ? (
              <img src={bars} width="60" height="60" alt="bars" />
            ) : (
              <img src={micicon} width="60" height="60" alt="micicon" />
            )}
          </div>
          {!medianotFound ? (
            <div>
              <div>
                <span>
                  {/*timer*/}
                  <h4 className="text-center">
                    {minutes} : {seconds}
                  </h4>
                </span>
              </div>
              {!recording ? (
                <div className="text-center mt-4">
                  {this.state.isReadyforUpload === false && (
                    <button
                      className={css.record}
                      onClick={(e) => this.startRecording(e)}
                    >
                      {/* <img src={microphone} width={30} height={30} alt="Microphone icons" /> */}
                      Record Conversation
                    </button>
                  )}
                  <p></p>
                  <div>
                    {this.state.isReadyforUpload &&
                      this.state.isUploading === false && (
                        <button
                          className={css.upload}
                          onClick={() =>
                            this.handleAudioUploadtoS3(this.state.audioBlob)
                          }
                        >
                          Upload
                        </button>
                      )}
                    <p></p>
                    {this.state.isReadyforUpload &&
                      this.state.isUploading === false && (
                        <>
                          <button
                            className={css.clear}
                            data-bs-toggle="modal"
                            data-bs-target="#clearAudioModal"
                          >
                            Delete
                          </button>
                          <div
                            className="modal fade"
                            id="clearAudioModal"
                            tabIndex="-1"
                            aria-labelledby="clearAudioModal"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="clearAudioModal"
                                  >
                                    Delete Audio
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  Are you sure you want to delete this audio
                                  file?
                                </div>
                                <div className={css.footer}>
                                  <button
                                    type="button"
                                    className={css.cancel}
                                    data-bs-dismiss="modal"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className={css.delete}
                                    onClick={(e) => this.handleReset(e)}
                                    data-bs-dismiss="modal"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <p></p>

                  <button
                    className={css.stop}
                    onClick={(e) => this.stopRecording(e)}
                  >
                    {/* <img
                      src={stopIcon}
                      width="20"
                      height="20"
                      alt="stop button"
                    /> */}
                    Stop
                  </button>
                  <p></p>
                  <div>
                    <div
                      onClick={
                        !pauseRecord
                          ? (e) => this.handleAudioPause(e)
                          : (e) => this.handleAudioStart(e)
                      }
                    >
                      <div>
                        {pauseRecord ? (
                          <button className={css.continue}>
                            {/* <img
                              src={playIcons}
                              width="20"
                              height="20"
                              alt="continue recording button"
                            /> */}
                            Continue Recording
                          </button>
                        ) : (
                          <button className={css.pause}>
                            {/* <img
                              src={pauseIcons}
                              width="20"
                              height="20"
                              alt="pause button"
                            /> */}
                            Pause
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p>Seems the site is Non-SSL</p>
          )}
        </div>

        {/*Uploading Progress Bar*/}
        {this.state.isUploading === true && (
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped w-100 progress-bar-animated"
              role="progressbar"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Uploading
            </div>
          </div>
        )}
        {this.state.isUploaded === true && (
          <div></div>
          // <div className="alert alert-success container-flex" role="alert">
          //   Audio uploaded
          // </div>
        )}
      </div>
    );
  }
}

export default Record;

Record.defaultProps = {
  hideHeader: false,
  mimeTypeToUseWhenRecording: null,
  handleCountDown: (data) => {},
};
