import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, subDays } from 'date-fns';

export default function Calendar({
  getData,
  formatDate,
  pickedDate,
  setPickedDate,
}) {
  // state variable that sets selectedDate to pickedDate
  const [selectedDate, setSelectedDate] = useState(new Date(pickedDate));

  useEffect(() => {
    // Sets picked date when the component mounts
    setPickedDate(formatSelectedDate(selectedDate));
  }, [selectedDate, setPickedDate]);

  useEffect(() => {
    // Update the selectedDate when another date is selected
    setSelectedDate(new Date(pickedDate));
  }, [pickedDate]);

  useEffect(() => {
    if (getData) getData(formatSelectedDate(selectedDate));
  }, [selectedDate, getData]);

  // formatting the date
  const formatSelectedDate = (date) => {
    return formatDate(date);
  };

  const dateForward = () => {
    setSelectedDate(addDays(selectedDate, 1))
  }

  const dateBackward = () => {
    setSelectedDate(subDays(selectedDate, 1))
  }


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // month and year dropdown is commented out for future usage if needed.
  return (
    <div className="flex w-fit shadow-knowtex-shadow rounded-xl border-0" data-testid="datepicker">
      <button className="block text-transparent-grey hover:text-light-grey min-w-[20px] text-xl font-bold text-light-grey mx-2" onClick={dateBackward}>
        <svg className="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 19-7-7 7-7" />
        </svg>
      </button>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="EEEE, MMMM d, yyyy"
        // showMonthDropdown
        // showYearDropdown
        dropdownMode="select"
        showIcon
        toggleCalendarOnIconClick
        todayButton="Today"
        className="rounded-md text-2xl border-none text-accent1"
      />
      <button className="block text-transparent-grey hover:text-light-grey min-w-[20px] text-xl text-light-grey mx-2" onClick={dateForward}>
        <svg className="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7" />
        </svg>
      </button>
    </div>
  );
}
