import React from "react";

// TODO: once store and state management is implemented, can move the logic from listnotes for the search feature in here. logic was added
// to listnotes instead of here to minimize prop drilling.

export default function SearchBar({ searchQuery, setSearchQuery }) {
  const pathName = window.location.pathname;

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="mt-5 relative items-center ">
      {pathName === '/dashboard' ? 
      <input
        type="text"
        placeholder="Search by MRN or Patient..."
        onChange={handleInputChange}
        className="border-0 focus:placeholder-white w-[270px] shadow-knowtex-shadow rounded-xl pl-10 pr-4 py-2 text-light-grey"
      /> :
      <input
        type="text"
        placeholder="Search by Date, MRN, or Patient..."
        onChange={handleInputChange}
        className="border-0 focus:placeholder-white w-[325px] shadow-knowtex-shadow rounded-xl pl-10 pr-4 py-2 text-light-grey"
      /> 
      }
      
      <svg
        className="absolute left-3 top-1/2 transform -translate-y-1/2 w-6 h-6 text-light-grey "
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        />
      </svg>
    </div>
  );
}
