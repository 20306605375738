import React from "react";
import bg from "../../assets/register_bg.jpg";
import whiteLogo from "../../assets/white-logo.png";

export default function RegisterSideBar1() {
  return (
    <>
      <div className="hidden flex-col p-10 justify-center items-center h-dvh w-[50dvw] bg-sidebar-background mobile:flex">
        <div className="w-[80%]">
          <img
            className="mb-4 ml-[-2.7rem]"
            style={{ height: "4.5rem", width: "12.5rem" }}
            src={whiteLogo}
            alt="White Logo"
          />
          <div className="flex flex-col">
            <h1 className="text-white font-extrabold text-3xl tracking-wider mb-6">
              Welcome to Knowtex!
            </h1>
          </div>
          <div className="flex flex-col text-[1rem] gap-[1rem] w-[80%] ml-[1.5rem]">
            <div className="relative flex items-start gap-[1rem] text-white">
              <svg
                className="h-4 w-4 absolute left-[-1.5rem] top-1 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <p>
                Knowtex will save you time with our automated
                note-taking and coding support.
              </p>
            </div>
            <div className="relative flex items-start gap-[1rem] text-white">
              <svg
                className="h-4 w-4 absolute left-[-1.5rem] top-1 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <p>
                Enjoy customized specialty-specific templates, automated coding
                suggestions, and real-time note turnaround.
              </p>
            </div>
            <div className="relative flex items-start gap-[1rem] text-white">
              <svg
                className="h-4 w-4 absolute left-[-1.5rem] top-1 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <p>
                Our proprietary private AI models offer multilingual and
                multimodal support, ensuring accurate documentation and
                streamlined reimbursement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
