import React, { Fragment, useState } from "react";
import SetUpRecord from "./SetUpRecord";
import { useLocation, useNavigate } from "react-router-dom";

import AudioRecorder from "./AudioRecorder";

import Navbar from "../Navbar";
import css from "./record.module.css";

//This is the page where conversation recording occurs for a single patient.
const RecordPage = ({ setAuth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const patientID = data.patientID;
  const patientName = data.patientName;
  const doctorName = data.doctorName;
  const MRN = data.MRN;
  const turnaroundTime = data.turnaroundTime;
  const [isAudio, setIsAudio] = useState(false);

  //new audio logic

  return (
    <Fragment>
      <div className="card-img-overlay">
        <p></p>
        <div className="container-fluid mt-5">
          {turnaroundTime === "Normal" &&
            (!isAudio ? (
              <button className={css.return} onClick={() => navigate(-1)}>
                Return
              </button>
            ) : (
              <button
                className={css.return}
                data-bs-toggle="modal"
                data-bs-target={`#areYouSure`}
              >
                Return
              </button>
            ))}
        </div>
        <div className="d-flex mt-5 justify-content-center">
          <h1 className="primary text-6xl text-dark-purple font-bold">{patientName}</h1>
        </div>
        <div className="d-flex mt-1 justify-content-center">
          <h3 className="primary text-2xl text-accent1">MRN {MRN}</h3>
        </div>
        <div className="d-flex justify-content-center">
          {turnaroundTime === "Normal" ? (
            <SetUpRecord
              setAuth={setAuth}
              patientID={patientID}
              name={doctorName}
              setIsAudio={setIsAudio}
            ></SetUpRecord>
          ) : (
            <AudioRecorder
              patientID={patientID}
              doctorName={doctorName}
              setIsAudio={setIsAudio}
            ></AudioRecorder>
          )}
        </div>
      </div>

      <div
        className="modal fade"
        id={`areYouSure`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="areYouSureLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="areYouSureLabel">
                Are you sure?
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              If you leave now your audio will not be uploaded. Are you sure you
              want to exit?
            </div>

            <div className={css.footer}>
              <button
                type="button"
                className={css.delete}
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className={css.cancel}
                data-bs-dismiss="modal"
                onClick={() => navigate(-1)}
              >
                Return Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RecordPage;
