import React, { useState, useEffect } from "react";
import SideBarV2 from "../SideBarV2/SideBarV2";
import ListNotes from "./ListNotes";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

//This page lists every patient/note associated with the doctor irregardless of date.
const AllNotes = ({ setAuth, setIsSubscribed, isSubscribed }) => {
  const [name, setName] = useState("");
  const [allPatients, setAllPatients] = useState([]);
  const [patientsChange, setPatientsChange] = useState(false);
  const [turnaroundTime, setTurnaroundTime] = useState("");
  const [org, setOrg] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const openSidebar = () => setIsSidebarOpen(true);
  const closeSidebar = () => setIsSidebarOpen(false);

  async function getName() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notes/`, {
        method: "GET",
        headers: { token: localStorage.token },
      });

      const parseRes = await response.json();
      setTurnaroundTime(parseRes[0].turnaround_time);
      setName(parseRes[0].user_name);
      setOrg(parseRes[0].organization);
      setAllPatients(parseRes);
      setIsLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getName();
    setPatientsChange(false);
  }, [patientsChange, isEditing]);

  // conditional to generate bg color based on the note status.
  const getStatusClassName = (status) => {
    switch (status) {
      case "Ready to Record":
        return "bg-ktx-green";
      case "Review":
        return "bg-accent2";
      case "Processing Audio":
        return "bg-light-grey";
      case "Locked":
        return "bg-dark-purple";
      default:
        return "";
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/subscription-status`,
          {
            method: "GET",
            headers: { token: localStorage.token },
          }
        );

        const parseRes = await response.json();
        setIsSubscribed(parseRes.is_subscribed);
      } catch (err) {
        console.error("Error fetching user info:", err.message);
      }
    })();
  }, [isSubscribed]);

  return (
    <div className="flex laptop:mt-0 mt-5">
      <SideBarV2
        isOpen={isSidebarOpen}
        onClose={closeSidebar}
        setAuth={setAuth}
      ></SideBarV2>
      {!isLoading ? (
        <div className="flex-col transition-margin ml-10 desktop:ml-dashboard-left">
          <button
            onClick={openSidebar}
            className="text-5xl text-accent2 rounded desktop:hidden"
          >
            ☰
          </button>
          <h2 className="text-3xl text-accent1 font-bold laptop:mt-0 mt-4">
            {name}'s Notes
          </h2>
          <div className="flex justify-center">
            <ListNotes
              allPatients={allPatients}
              setPatientsChange={setPatientsChange}
              name={name}
              turnaroundTime={turnaroundTime}
              org={org}
              getName={getName}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              getStatusClassName={getStatusClassName}
            ></ListNotes>
          </div>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};

export default AllNotes;
