import React, { useState } from "react";
import BulkDeleteModal from "./BulkDeleteModal";

const BulkDeletePatients = ({setSelectAll, userId, notesToShow, selectedPatients, setSelectedPatients, pickedDate ,allPatients, setPatientsChange }) => {
    const [showDeleteAllModal, setShowDeleteAllModal] = useState(false); // default modal state is false

    const handleModalClick = () => {
        setShowDeleteAllModal(true)
    };

    return (
        <div className="flex absolute bottom-[8px] right-[-150px] justify-end min-w-[110px] text-transparent-grey hover:text-light-grey">
            <button onClick={handleModalClick} className="flex">
                <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                </svg>
                <p className="ml-1">Delete Selected</p>
            </button>
            {showDeleteAllModal ? <BulkDeleteModal setSelectAll={setSelectAll} userId={userId} setSelectedPatients={setSelectedPatients} selectedPatients={selectedPatients} notesToShow={notesToShow} showDeleteAllModal={showDeleteAllModal} setPatientsChange={setPatientsChange} setShowDeleteAllModal={setShowDeleteAllModal} pickedDate={pickedDate} allPatients={allPatients} /> : null}
        </div>
    );
};

export default BulkDeletePatients;
