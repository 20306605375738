import React, { Fragment, useEffect, useState } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import axios from "axios";
import Scroll from "./components/Scroll";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

//components
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/Login";
import Register1 from "./components/RegistrationFlow/Register1";
import AllNotes from "./components/noteList/AllNotes";
import PageNotFound from "./components/PageNotFound";
import RecordPage from "./components/dashboard/RecordPage";
import SubscriptionPage from "./components/RegistrationFlow/SubscriptionPage";
import Settings from "./components/settings/Settings";
import Subscribe from "./components/Stripe/Checkout";
import UnsubscribeForm from "./components/UnsubscribeForm";

//this is to debug env variables
//console.log(process.env);
import LogRocket from "logrocket";

LogRocket.init("b0hpsh/knowtex-prod");

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(null);

  const setAuth = (boolean) => {
    setIsAuthenticated(boolean);
  };

  function sendEvent(userEvent) {
    // Replace "http://example.com/api/events" with the actual endpoint URL.
    const endpointUrl =
      "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";

    axios
      .post(endpointUrl, userEvent)
      .then((response) => {
        //console.log("Event sent successfully:", response.data);
      })
      .catch((error) => {
        //console.error("Failed to send event:", error);
      });
  }

  async function isAuth() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/is-verify`,
        {
          method: "GET",
          headers: { token: localStorage.token },
        }
      );

      const parseRes = await response.json();
      parseRes === true ? setIsAuthenticated(true) : setIsAuthenticated(false);
      // if user authorized, update the log
      const decoded_token = jwt_decode(localStorage.token);
      const user_id = decoded_token["user"]["id"];

      //console.log("User:%s logged back through token \n", user_id);
      // User log in failed
      window.gtag("event", "user_logged_back_through_token", {
        app_name: "Knowtex-Webapp",
        screen_name: "Login Page",
        job_name: user_id,
      });

      //log rocket
      LogRocket.identify(user_id);

      // Define the event
      const userEvent = {
        env: process.env.REACT_APP_API_URL.includes("localhost")
          ? "local"
          : "prod",
        service: "knowtex-webApp",
        event: {
          eventName: "user_logged_back_through_token",
          userId: user_id,
          screenName: "Login Page",
          timestamp: new Date().toISOString(),
        },
      };
      sendEvent(userEvent);

      // Check subscription status
      const subscriptionResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/subscription-status`,
        {
          method: "GET",
          headers: { token: localStorage.token },
        }
      );

      const subscriptionData = await subscriptionResponse.json();
      setIsSubscribed(subscriptionData.is_subscribed);
    } catch (err) {
      console.error("Authentication/Subscription check failed:", err.message);
    }
  }

  useEffect(() => {
    isAuth();
  }, [isSubscribed]);

  return (
    <Fragment>
      {isAuthenticated !== null && (
        <Router>
          <Scroll />
          <Routes>
            <Route
              exact
              path="/login"
              element={
                !isAuthenticated ? (
                  <Login setAuth={setAuth} setIsSubscribed={setIsSubscribed} />
                ) : isSubscribed !== false ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/subscription" />
                )
              }
            />
            <Route
              exact
              path="/register"
              element={<Register1 setAuth={setAuth} />}
            />
            <Route
              exact
              path="/dashboard"
              element={
                isAuthenticated ? (
                  isSubscribed !== false ? (
                    <Dashboard setAuth={setAuth} setIsSubscribed={setIsSubscribed} isSubscribed={isSubscribed} />
                  ) : (
                    <Navigate to="/subscription" />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/notes"
              element={
                isAuthenticated ? (
                  isSubscribed !== false ?
                    <AllNotes setAuth={setAuth} setIsSubscribed={setIsSubscribed} isSubscribed={isSubscribed} />
                    :
                    <Navigate to="/subscription" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/record"
              element={
                isAuthenticated ? (
                  isSubscribed !== false ?
                    <RecordPage setAuth={setAuth} />
                    :
                    <Navigate to="/subscription" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/"
              element={
                !isAuthenticated ? (
                  <Login setAuth={setAuth} />
                ) : !isSubscribed ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/subscription"
              element={
                !isAuthenticated ? (
                  <Navigate to="/login" />
                ) : isSubscribed !== false ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <SubscriptionPage setAuth={setAuth} />
                )
              }
            />
            <Route
              exact
              path="/settings"
              element={
                isAuthenticated ? (
                  isSubscribed !== false ? (
                    <Settings setAuth={setAuth} setIsSubscribed={setIsSubscribed} isSubscribed={isSubscribed} />
                  ) : (
                    <Navigate to="/subscription" />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/subscribe"
              element={
                isAuthenticated ? (
                  isSubscribed !== false ? (
                    <Navigate to="/dashboard" />
                  ) : (
                    <Subscribe setAuth={setAuth} setIsSubscribed={setIsSubscribed} isSubscribed={isSubscribed} />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              } />
            <Route
              exact
              path="/unsubscribe"
              element={
                isAuthenticated ? (
                  isSubscribed ? (
                    <UnsubscribeForm />
                  ) : (
                    isSubscribed === null ?
                      <Navigate to="/dashboard" />
                      :
                      <Navigate to="/subscription" />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              } />
            <Route exact path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      )}
      <div>
        <ToastContainer />
      </div>
    </Fragment>
  );
}

export default App;
